<template>
  <div>
    <div id="app">
      <vue-event-calendar :events="slots">
        <template slot-scope="props">
          <!-- Filter and Button -->
          <div class="filter">
            <multiselect class="influencer-select mb-4"
                        :custom-label="influencerName"
                        :max-height="150"
                        :multiple="false"
                        :options="influencers"
                        placeholder="Filter by Influencer"
                        :searchable="true"
                        track-by="user_id"
                        v-model="influencer"
            />
            <button class="btn btn-sm btn-success ml-1"
                    @click="createSchedule"
                    type="button"
                    title="Create Schedule">
              <i class="fas fa-plus"></i> Schedule
            </button>
          </div>

          <!-- No Schedule -->
          <div v-if="props.showEvents.length === 0">
            <p class="small text-center text-white">No Schedules Found</p>
          </div>

          <!-- Events List -->
          <div v-for="(event, index) in props.showEvents"
               :key="index"
               class="event-item"
               :class="event.status">
            <div class="row">
              <div class="col-lg-7 align-middle">
                <p class="mb-0 xsmall">
                  <span class="label">Influencer</span> : {{ event.influencer }}
                </p>
                <p class="mb-0 xsmall" v-show="event.status === 'reserved'">
                  <span class="label">Educator</span> : {{ event.educator }}
                </p>
                <p class="mb-0 xsmall">
                  <span class="label">Start Time</span> : {{ event.startTime }}
                </p>
                <p class="mb-0 xsmall">
                  <span class="label">End Time</span> : {{ event.endTime }}
                </p>
              </div>
              <div class="col-lg-5 text-right">
                <button class="btn btn-success edit-btn"
                        @click="editSchedule(event)"
                        type="button"
                        :update-schedule-list="updateScheduleList"
                        title="Edit Schedule">
                  <i class="fas fa-edit"></i> Edit
                </button>
                <br>
                <button class="btn btn-danger delete-btn"
                        @click="deleteSchedule(event)"
                        type="button"
                        title="Delete Schedule">
                  <i class="far fa-trash-alt"></i> Delete
                </button>
              </div>
            </div>
          </div>
        </template>
      </vue-event-calendar>
    </div>
  </div>
</template>

<script>
  const Multiselect = () => import('vue-multiselect');
  const EditSchedule = () => import('./EditSchedule.vue');
  const CreateSchedule = () => import('./CreateSchedule.vue');

  import moment from 'moment-timezone';
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name : 'Schedule',
    data() {
      return {
        userTz      : this.$store.getters['user/user'].timezone,
        slots       : [],
        influencers : [],
        influencer  : null,
      }
    },
    components : {
      Multiselect,
    },
    computed : {
      ...mapGetters({
        schedules : 'broadcast/schedules',
      }),
    },
    watch : {
      schedules(value) {
        this.slots = [];
        if (value.length > 0) {
          for (let i = 0; i < value.length; i++) {
            const startDate = moment.utc(value[i].start_time);
            const endDate = moment.utc(value[i].end_time);
            const duration = moment.duration(endDate.diff(startDate)).minutes();

            const sched = {
              date       : startDate.tz(this.userTz).format('YYYY/MM/DD'),
              title      : startDate.tz(this.userTz).format('LL'),
              id         : value[i].broadcast_slot_id,
              influencer : value[i].inf_fname + ' ' + value[i].inf_lname,
              educator   : value[i].educ_fname + ' ' + value[i].educ_lname,
              startTime  : moment.tz(startDate, this.userTz)
                .format('hh:mm A z'),
              endTime : moment.tz(endDate, this.userTz).format('hh:mm A z'),
              status  : value[i].status,
              start   : startDate, // in utc
              end     : endDate, // in utc

              // for Edit Schedule Modal
              influencerId : value[i].inf_id,
              educatorId   : value[i].educ_id,
              duration     : duration,
              startDisp    : startDate.tz(value[i].inf_timezone)
                .format('HH:mm'),
              endDisp  : endDate.tz(value[i].inf_timezone).format('HH:mm'),
              dateDisp : startDate.tz(value[i].inf_timezone)
                .format('YYYY-MM-DD'),
              notes        : value[i].notes,
              infTimezone  : value[i].inf_timezone,
              educTimezone : value[i].educ_timezone,
            };

            this.slots.push(sched);
          }
        }
      },
      influencer(value) {
        this.slots = [];
        if (value)
          this.getSchedules(value.user_id);
        else
          this.getSchedules();
      },
    },
    methods : {
      ...mapActions({
        getSchedules : 'broadcast/getSchedules',
      }),

      /**
       * Custom Label for Influencer Multiselect
       */
      influencerName({ firstname, lastname }) {
        return `${firstname} ${lastname}`;
      },

      /**
       * Create Schedule
       */
      createSchedule() {
        this.$modal.show(
          CreateSchedule, {
            influencers : this.influencers,
          }, {
            height   : 'auto',
            adaptive : true,
          }, {
            // eslint-disable-next-line no-unused-vars
            'create-schedule-successful' : (event) => {
              // update schedules list
              if (this.influencer)
                this.getSchedules(this.influencer.user_id);
              else
                this.getSchedules();
            },
          }
        );
      },

      /**
       * Edit Schedule
       * @param data
       */
      editSchedule(data) {
        this.$modal.show(EditSchedule, {
          data : data,
        }, {
          height   : 'auto',
          adaptive : true,
        }, {
          'before-close' : (event) => {
            // update schedules list
            if (this.influencer)
              this.getSchedules(this.influencer.user_id);
            else
              this.getSchedules();
          },
        });
      },

      /**
       * Delete Schedule
       * @param id
       */
      deleteSchedule(data) {
        this.$modal.show('dialog', {
          title   : 'Delete Schedule',
          text    : 'Are you sure you want to delete this schedule?',
          buttons : [ {
            title   : 'Yes',
            handler : () => {
              this.$http.delete('api/broadcast/slot', {
                data : {
                  broadcastSlotId : data.id,
                  broadcast       : data,
                },
              }).then(() => {
                // update schedules list
                if (this.influencer)
                  this.getSchedules(this.influencer.user_id);
                else
                  this.getSchedules();
                
                this.$notify({
                  group : 'notif',
                  type  : 'success',
                  title : 'Success',
                  text  : 'Deleted schedule/slot successfully!',
                });
                this.$modal.hide('dialog');
              }).catch(() => {
                this.$notify({
                  group : 'notif',
                  type  : 'error',
                  title : 'Failed',
                  text  : 'Oops! Something went wrong!',
                });
              });
            },
          }, {
            title : 'No',
          } ],
        });
      },

      handleDayChanged (data) {
        // console.log('date-changed', data)
      },
      handleMonthChanged (data) {
        // console.log('month-changed', data)
      },

      updateScheduleList() {
        // console.log('Update Schedule List');
      },

      /**
       * Get All Influencers
       */
      getInfluencers() {
        this.$http.get('api/broadcast/influencers', {
          params : {
            page    : 1,
            perPage : 1000,
          },
        }).then((response) => {
          this.influencers = response.data.data;
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        });
      },
    },
    mounted() {
      this.getInfluencers();
      this.getSchedules();
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/admin/influencers/schedule";
</style>